.slide-show {
  position: absolute;
  width: 100%;
  max-width: 600px;
  margin: auto;
  overflow: hidden;
}

.slide-content {
  text-align: left;
  font-size: 24px;
  padding: 0;
  background-color: none;
}

.dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  width: 1.2rem;
  height: 1.2rem;
  background-color: var(--dark2);
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: var(--primaryYellow);
}

@media screen and (max-width: 1125px) {
  .slide-content {
    word-wrap: break-word;
    overflow: hidden;
  }

  .slide-show {
    position: static;
    width: 100%;
    overflow: hidden;
  }
}
