.navbar {
  display: grid;
  grid-auto-flow: column;
  padding: 2.4rem 8rem;
  grid-template-columns: 1fr 2fr;
  background-color: transparent;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 8888;
  max-width: 1600px;
  box-sizing: border-box;
}
.navbar.scrolled {
  background-color: var(--white);
  opacity: 1;
  padding: 1.2rem 8rem;
}
.navbar.scrolled .navLinks a {
  color: var(--dark);
}
.navbar.scrolled .navLinks a:hover {
  color: var(--primaryYellow);
  cursor: pointer;
}
.navbar.scrolled .navLinks a.active {
  color: var(--primaryYellow);
  font-weight: 600;
}
.navLinks {
  align-content: center;
  justify-self: end;
}
.navbar .primaryBtn {
  text-decoration: none;
  background-color: var(--primaryYellow);
  border-radius: 0.8rem;
  border: none;
  color: var(--white);
  margin-left: 2rem;
  padding: 1.2rem 3.2rem;
  font-weight: 600;
  text-align: center;
  font-size: var(--pReg);
  cursor: pointer;
}
.navLinks a {
  text-decoration: none;
  color: var(--dark);
  margin: 2rem;
  font-size: var(--pReg);
}
.navLinks a:hover {
  color: var(--primaryYellow);
  cursor: pointer;
}
.navLinks a.active {
  color: var(--primaryYellow);
  font-weight: 600;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}
.hamburger span {
  height: 0.4rem;
  width: 2.4rem;
  background: var(--primaryYellow);
  margin: 0.2rem;
  border-radius: 5px;
}

@media (max-width: 1125px) {
  .navbar {
    padding: 1rem 2rem 0 2rem;
    background-color: transparent;
    width: 100%;
  }
  .navbar.scrolled {
    padding: 1rem 2rem 0 2rem;
  }
  .navLinks {
    display: none;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #333;
    z-index: 1000;
    justify-content: center;
    overflow: hidden;
  }
  /* .logo {
    position: absolute;
    float: left;
    z-index: 1000;
  } */
  .hamburger {
    display: flex;
    margin-top: 0.4rem;
    justify-self: end;
  }
  .hamburger.open span {
    background: #fff;
  }
  .navLinks a {
    margin: 1rem;
    font-size: 0.9rem;
  }
  .navbar .primaryBtn {
    padding: 1rem 2.5rem;
    font-size: 0.9rem;
  }
}
