.contactText {
  text-align: center;
  margin-bottom: 8rem;
}

.contactText h1 {
  font-size: var(--h2);
  color: var(--dark);
  font-weight: 400;
  line-height: 56px;
}

.contactText h1 span {
  color: var(--primaryYellow);
  font-weight: 600;
}

.contactUs {
  padding: 8rem;
}
.contactDetails {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-around;
  width: 80%;
  margin: 0 auto;
}

.contactDetails .email,
.contactDetails .phone,
.contactDetails .social {
  text-align: center;
}

.contactDetails .title h4 {
  font-size: var(--h5);
  font-weight: 600;
  color: var(--dark);
}

.contactDetails .value {
  display: flex;
  justify-content: center;
  margin-top: 0.8rem;
}

.contactDetails .reachUs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.8rem;
}

.contactDetails .reachUs .valuePhone {
  display: flex;
  justify-content: center;
}

.contactDetails .reachUs .valueWhatsapp {
  display: flex;
  justify-content: center;
}

.contactDetails .reachUs .valueWhatsapp p {
  padding-top: 0.4rem;
}

.contactDetails .reachUs .valueWhatsapp a {
  text-decoration: none;
}

.contactDetails .value img {
  padding: 0.2rem;
}

.contactDetails .value p {
  font-size: var(--pReg);
  color: var(--dark1);
  margin-right: 0.8rem;
}

.contactDetails .value img {
  margin-right: 0.8rem;
}

.emailbtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 1125px) {
  .contactText {
    margin-bottom: 4rem;
  }

  .contactDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 80%;
    gap: 40px;
  }

  .contactText h1 {
    font-size: var(--h4);
  }

  .contactUs {
    padding: 4rem 2.4rem;
  }

  .contactDetails .title h4 {
    font-size: var(--pLarge);
    font-weight: 400;
    color: var(--dark);
  }

  .contactDetails .value {
    display: flex;
    justify-content: center;
    margin-top: 0.4rem;
  }

  .contactDetails .value p {
    font-size: var(--pSmall);
    color: var(--dark1);
  }
}
