.aboutus-container {
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
}

.aboutus-banner {
  display: grid;
  column-gap: 2.4rem;
  grid-template-columns: 1fr 1fr;
  margin: 24rem 8rem 8rem 8rem;
}

.aboutus-banner .aboutus-bannerLeft {
  align-content: center;
  width: 90%;
}

.aboutus-bannerLeft h1 {
  font-size: var(--h1);
  color: var(--dark);
  font-weight: 400;
  margin-bottom: 40px;
  line-height: 56px;
}

.aboutus-bannerLeft h1 span {
  color: var(--primaryYellow);
  font-weight: 600;
}

.aboutus-bannerLeft p {
  font-size: var(--pReg);
  color: var(--dark2);
  line-height: 24px;
}

.aboutus-bannerLeft p a {
  text-decoration: none;
  color: var(--primaryYellow);
  font-weight: 600;
}

.aboutus-bannerRight p {
  font-size: var(--pReg);
  color: var(--dark1);
  line-height: 24px;
  margin-bottom: 4rem;
}

.aboutus-talktous {
  display: grid;
  column-gap: 2.4rem;
  grid-template-columns: 1fr 1fr;
  padding: 8rem;
  background-color: var(--dark);
}

.aboutus-talktous .gridLeft {
  align-content: center;
  width: 90%;
}

.aboutus-talktous .gridLeft h2 {
  font-size: var(--h2);
  color: var(--white);
  font-weight: 400;
  margin-bottom: 40px;
  line-height: 56px;
}

.aboutus-talktous .gridLeft .primaryBtn {
  text-decoration: none;
  background-color: var(--primaryYellow);
  border-radius: 8px;
  border: none;
  color: var(--white);
  padding: 1.2rem 3.2rem;
  font-weight: bold;
  text-align: center;
}

.aboutus-talktous .talktous-btnresponsive .primaryBtn {
  display: none;
}

.aboutus-talktous .gridRight h5 {
  font-size: var(--h5);
  color: var(--white);
  line-height: 24px;
  margin-bottom: 4rem;
}

.aboutus-talktous .gridRight p {
  font-size: var(--pReg);
  color: var(--white);
  line-height: 24px;
}

.aboutus-process {
  padding: 8rem;
}

.aboutus-process .sectionTitle {
  text-align: center;
}

.aboutus-process .sectionTitle h2 {
  font-size: var(--h2);
  color: var(--dark);
  font-weight: 400;
  margin-bottom: 40px;
  line-height: 56px;
}

.aboutus-process .sectionTitle span {
  color: var(--primaryYellow);
  display: block;
}

.aboutus-process .sectionImg {
  text-align: center;
}

.aboutus-process .sectionImg img {
  max-width: 100%;
}

.aboutus-philosophy {
  padding: 8rem;
  background-color: var(--dark);
}

.aboutus-philosophy .sectionTitle {
  display: grid;
  column-gap: 2.4rem;
  grid-template-columns: 1fr 1fr;
}

.aboutus-philosophy .sectionTitle h2 {
  font-size: var(--h2);
  color: var(--white);
  font-weight: 400;
  margin-bottom: 8rem;
  line-height: 56px;
}

.aboutus-philosophy .sectionTitle h2 span {
  color: var(--primaryYellow);
}

.aboutus-philosophy .sectionTitle p {
  font-size: var(--pReg);
  color: var(--grey1);
  line-height: 24px;
}

.aboutus-philosophy .sectionContent {
  display: grid;
  grid-template-areas: "philosophy1 philosophy2 philosophy3 philosophy4";
  gap: 2rem;
}

.aboutus-philosophy .sectionContent .bgcolordark {
  background-color: var(--dark1);
}

.philosophy1 {
  grid-area: philosophy1;
  padding: 2.4rem;
}

.philosophy2 {
  grid-area: philosophy2;
  padding: 2.4rem;
}

.philosophy3 {
  grid-area: philosophy3;
  padding: 2.4rem;
}

.philosophy4 {
  grid-area: philosophy4;
  padding: 2.4rem;
}

.aboutus-philosophy .sectionContent h5 {
  font-size: var(--h5);
  color: var(--white);
  line-height: 24px;
  font-weight: 600;
  margin: 2rem 0;
}

.aboutus-philosophy .sectionContent p {
  font-size: var(--pReg);
  color: var(--grey1);
  font-weight: 400;
  line-height: 24px;
}

@media screen and (max-width: 1125px) {
  .aboutus-container {
    width: 100%;
    overflow: hidden;
    height: 100%;
    margin-top: 8rem;
  }

  .aboutus-banner {
    display: flex;
    flex-direction: column;
    margin: 4rem 2.4rem;
  }

  .aboutus-bannerLeft h1 {
    font-size: var(--h3);
    color: var(--dark);
    font-weight: 400;
    margin-bottom: 2.4rem;
    line-height: 56px;
  }

  .aboutus-bannerLeft p {
    font-size: var(--pSmall);
    color: var(--dark2);
    line-height: 24px;
  }

  .aboutus-bannerRight p {
    font-size: var(--pSmall);
    color: var(--dark1);
    line-height: 24px;
    margin-bottom: 2.4rem;
  }

  .aboutus-talktous {
    display: flex;
    flex-direction: column;
    padding: 2.4rem;
    background-color: var(--dark);
  }

  .aboutus-talktous .gridLeft h2 {
    font-size: var(--h4);
    color: var(--white);
    font-weight: 400;
    margin-bottom: 2.4rem;
    line-height: 40px;
  }

  .primaryBtn {
    width: 100%;
  }

  .aboutus-talktous .gridRight h5 {
    font-size: var(--h5);
    color: var(--white);
    line-height: 24px;
    margin-bottom: 2.4rem;
    font-weight: 400;
  }

  .aboutus-talktous .gridRight p {
    font-size: var(--pSmall);
  }

  .aboutus-talktous .gridLeft .primaryBtn {
    display: none;
  }

  .aboutus-talktous .talktous-btnresponsive {
    margin: 0;
  }

  .aboutus-talktous .talktous-btnresponsive .primaryBtn {
    text-decoration: none;
    background-color: var(--primaryYellow);
    border-radius: 8px;
    border: none;
    color: var(--white);
    padding: 1.2rem 3.2rem;
    font-weight: bold;
    text-align: center;
    display: inline-block;
  }

  .aboutus-process {
    padding: 4rem 2.4rem;
  }

  .aboutus-philosophy {
    padding: 2.4rem;
  }

  .aboutus-philosophy .sectionTitle {
    display: flex;
    flex-direction: column;
    margin-bottom: 2.4rem;
  }

  .aboutus-philosophy .sectionTitle h2 {
    font-size: var(--h4);
    color: var(--white);
    font-weight: 400;
    margin-bottom: 2.4rem;
    line-height: 56px;
  }

  .aboutus-philosophy .sectionTitle p {
    font-size: var(--pSmall);
    color: var(--grey1);
    line-height: 24px;
  }

  .aboutus-philosophy .sectionContent {
    display: flex;
    flex-direction: column;
  }

  .aboutus-process .sectionTitle h2 {
    font-size: var(--h4);
    color: var(--dark);
    font-weight: 400;
    margin-bottom: 40px;
    line-height: 40px;
  }
}
