footer {
  background-color: var(--dark);
}
.nav-footer {
  display: grid;
  grid-auto-flow: column;
  margin: 0 8rem;
  grid-template-columns: 1fr 2fr 1fr;
  padding: 2.4rem 0;
}

.nav-footer .navLinks {
  align-content: center;
  justify-self: center;
}

.copyrightdiv {
  align-content: center;
  justify-self: end;
}

.copyright {
  color: var(--dark2);
  font-size: var(--pSmall);
}

.nav-footer .navLinks a {
  text-decoration: none;
  color: var(--white);
  margin: 2rem;
}

.navLinks a:hover {
  color: var(--primaryYellow);
  cursor: pointer;
}

.navLinks a.active {
  color: var(--primaryYellow);
  font-weight: 600;
}

@media screen and (max-width: 1125px) {
  .footer {
    max-width: 100%;
  }
}
