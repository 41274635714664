* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "poppins";
}

html {
  font-size: 62.5%;
}

:root {
  /* font sizes */
  --h0: 4.8rem;
  --h1: 4rem;
  --h2: 3.2rem;
  --h3: 2.8rem;
  --h4: 2.4rem;
  --h5: 2rem;
  --pLarge: 1.8rem;
  --pReg: 1.6rem;
  --pSmall: 1.4rem;
  --pXsmall: 1rem;

  /* colors */
  --dark: #2f2d28;
  --dark1: #595753;
  --dark2: #82817e;
  --dark3: #acaba9;
  --grey1: #d5d5d4;
  --grey2: #eaeae9;
  --grey3: #f5f4f4;
  --white: #ffffff;
  --primaryYellow: #efb138;
  --primaryYellowLight: #fff4e1;
  --primaryYellowDark: #ac6e00;
  --primaryBlue: #275b8f;
  --primaryBlueLight: #eef7ff;
  --primaryBlueDark: #174069;
  --accentGreen: #219653;
  --accentRed: #eb5757;
}

/* .container {
  max-width: 1600px;
} */

a:focus,
button:focus,
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

a:active,
button:active,
input:active,
textarea:active,
select:active {
  outline: none;
}

a,
button {
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
}

@media (max-width: 1125px) {
  .container {
    margin: auto;
  }
}
