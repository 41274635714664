.services-container {
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
}

.services-banner {
  display: grid;
  column-gap: 2.4rem;
  grid-template-columns: 1fr 1fr;
  margin: 24rem 8rem 8rem 8rem;
}

.services-banner .services-bannerLeft {
  align-content: center;
  width: 90%;
}

.services-bannerLeft h1 {
  font-size: var(--h1);
  color: var(--dark);
  font-weight: 400;
  margin-bottom: 40px;
  line-height: 56px;
}

.services-bannerLeft h1 span {
  color: var(--primaryYellow);
  font-weight: 600;
}

.services-bannerLeft p {
  font-size: var(--pReg);
  color: var(--dark2);
  line-height: 24px;
}

.services-bannerLeft p a {
  text-decoration: none;
  color: var(--primaryYellow);
  font-weight: 600;
}

.services-bannerRight p {
  font-size: var(--pReg);
  color: var(--dark2);
  line-height: 24px;
  margin-bottom: 40px;
}

.services-info {
  padding: 8rem;
  background-color: var(--dark);
  display: grid;
  column-gap: 2.4rem;
  grid-template-columns: 1fr 1fr;
}

.services-info .services-infoLeft h2 {
  font-size: var(--h2);
  color: var(--white);
  font-weight: 400;
  margin-bottom: 4rem;
}

.services-info .services-infoLeft .services-infoLeft-header {
  color: var(--primaryYellow);
  font-weight: 600;
}

.services-infoLeft .service-names {
  display: grid;

  gap: 2rem;
  color: var(--grey1);
  font-size: var(--h4);
  padding-top: 2rem;
}

.services-infoLeft .service-names .service {
  padding: 1rem 0;
}

.services-infoLeft .service-names .service:hover {
  color: var(--white);
  font-weight: 600;
  cursor: pointer;
}

.services-infoLeft .service-names .activeService {
  color: var(--primaryYellow);
  font-weight: 600;
}

.services-infoLeft .service-names .activeService:hover {
  color: var(--primaryYellow) !important;
  font-weight: 600 !important;
}

.services-info .services-infoRight {
  display: grid;
  column-gap: 2.4rem;
  grid-template-rows: auto 1fr;
  padding-top: 1.4rem;
}

.services-infoRight .infoRightDiv h5 {
  font-size: var(--h4);
  color: var(--white);
  line-height: 24px;
  margin-bottom: 2rem;
  font-weight: 500;
}

.services-infoRight .infoRightDiv p {
  font-size: var(--pReg);
  color: var(--grey1);
  line-height: 24px;
}

.services-infoRight .infoRightBoxes {
  display: grid;
  grid-template-areas:
    "infoRightGrid1 infoRightGrid2"
    "infoRightGrid3 infoRightGrid4";
  gap: 2rem;
  padding-top: 4rem;
}

.infoRightBoxes .infoRightBoxesHeader {
  font-size: var(--h5);
  color: var(--white);
  line-height: 24px;
  padding-bottom: 1.6rem;
}

.infoRightBoxes .infoRightBoxesText {
  font-size: var(--pReg);
  color: var(--grey1);
  line-height: 24px;
}

.infoRightBoxes .infoRightGrid {
  border-radius: 0.8rem;
  background-color: var(--dark1);
  padding: 1.6rem;
}

.infoRightBoxes .infoRightGrid {
  border-radius: 0.8rem;
  background-color: var(--dark1);
  padding: 1.6rem;
}

.infoRightBoxes .infoRightGrid {
  border-radius: 0.8rem;
  background-color: var(--dark1);
  padding: 1.6rem;
}

.infoRightBoxes .infoRightGrid {
  border-radius: 0.8rem;
  background-color: var(--dark1);
  padding: 1.6rem;
}

@media screen and (max-width: 1125px) {
  .services-container {
    width: 100vw;
    overflow: hidden;
    height: 100%;
    margin-top: 8rem;
  }

  .services-banner {
    display: flex;
    margin: 4rem 2.4rem;
    flex-direction: column;
  }

  .services-banner .services-bannerLeft {
    align-content: center;
    width: 90%;
  }

  .services-bannerLeft h1 {
    font-size: var(--h3);
    color: var(--dark);
    font-weight: 400;
    margin-bottom: 2.4rem;
    line-height: 56px;
  }

  .services-bannerLeft h1 span {
    color: var(--primaryYellow);
    font-weight: 600;
  }

  .services-bannerLeft p {
    font-size: var(--pSmall);
    color: var(--dark2);
    line-height: 24px;
  }

  .services-bannerLeft p a {
    text-decoration: none;
    color: var(--primaryYellow);
    font-weight: 600;
  }

  .services-bannerRight p {
    font-size: var(--pSmall);
    color: var(--dark2);
    line-height: 24px;
    margin-bottom: 2.4rem;
  }

  .services-info {
    padding: 2.4rem;
    background-color: var(--dark);
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .services-info .services-infoLeft h2 {
    font-size: var(--h4);
    color: var(--white);
    font-weight: 400;
    margin-bottom: 2.4rem;
  }

  .services-info .services-infoLeft h2 span {
    color: var(--primaryYellow);
    font-weight: 600;
  }

  .services-infoRight .infoRightBoxes {
    display: flex;
    flex-direction: column;
    padding-top: 2.4rem;
  }

  .services-info .services-infoRight {
    padding-top: 2.4rem;
  }

  .services-infoRight .infoRightDiv h5 {
    font-size: var(--h5);
    font-weight: 500;
  }

  .services-accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.4rem 0;
    cursor: pointer;
    border-bottom: 0.4px solid var(--dark1);
  }

  .services-accordion-header .accordion-icon {
    float: right;
  }

  .services-accordion-header .accordion-header {
    float: left;
    font-size: var(--h5);
  }

  .services-accordion-header {
    color: var(--grey1);
  }

  .active-header {
    color: var(--primaryYellow);
  }
}
