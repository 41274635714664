.container {
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

.banner {
  background-repeat: no-repeat;
  background-size: 100%;
  height: 80rem;
}

.bannerContent {
  display: grid;
  column-gap: 2.4rem;
  grid-template-columns: 1fr 1fr;
  padding: 28rem 8rem 8rem 8rem;
}

.banner .primaryBtn {
  position: relative;
  text-decoration: none;
  background-color: var(--white);
  border-radius: 4rem;
  color: var(--primaryYellow);
  border: 2px solid var(--primaryYellow);
  padding: 1.6rem 4rem;
  font-weight: 600;
  text-align: center;
  font-size: var(--h5);
  z-index: 1;
  margin-bottom: 8rem;
  overflow: hidden;
}

.banner .primaryBtn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primaryYellow);
  z-index: -1;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.6s ease;
  color: var(--white);
  border-radius: 4rem;
}

.banner .primaryBtn:hover::after {
  transform: scaleX(1);
}

.banner .primaryBtn:hover {
  color: white;
}

.banner .bannerLeft {
  display: grid;
  grid-auto-flow: row;
  gap: 5.6rem;
  justify-content: space-between;
}

.banner .bannerText {
  align-content: center;
  width: 100%;
}

.bannerText h1 {
  font-size: var(--h0);
  color: var(--dark);
  font-weight: 400;
  margin-bottom: 4rem;
  line-height: 56px;
}

.bannerText h1 span {
  color: var(--primaryYellow);
  font-weight: 600;
}

.bannerText p {
  font-size: var(--pReg);
  color: var(--dark2);
  line-height: 24px;
  margin-bottom: 40px;
}
.siteOffers h4 {
  font-size: var(--h5);
  font-weight: 400;
  color: var(--dark);
  margin-bottom: 1.6rem;
}
.siteOffers p {
  font-size: var(--pReg);
  font-weight: 400;
  color: var(--dark2);
}
.banner .offers {
  grid-template-columns: 1fr 1fr;
  display: grid;
  grid-auto-flow: column;
  gap: 2.4rem;
}

.banner .offers .title {
  grid-template-columns: auto auto;
  display: flex;
  column-gap: 1.6rem;
  align-items: center;
  margin-bottom: 0.8rem;
}

.offers .title img {
  display: inline-block;
}

.offers .title h4 {
  font-size: var(--h5);
  font-weight: 400;
  color: var(--dark);
}

.offers .para p {
  font-size: var(--pSmall);
  color: var(--dark2);
}

.offers .moneyBack,
.offers .randomOffer {
  background: var(--grey3);
  border-radius: 0.8rem;
  padding: 2.4rem;
}

.bannerImg img {
  border-radius: 4px;
  width: 100%;
}

.banner .bannerImg {
  text-align: end;
}

.aboutUsRapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.aboutUs {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  background-color: var(--dark);
  padding: 8rem;
}

.aboutText .primaryBtn {
  text-decoration: none;
  background-color: var(--primaryYellow);
  border-radius: 8px;
  border: none;
  color: var(--white);
  padding: 1.2rem 3.2rem;
  font-weight: bold;
  text-align: center;
}

.aboutText h1 {
  font-size: var(--h2);
  color: var(--white);
  font-weight: 400;
  line-height: 56px;
}

.aboutText h1 span {
  color: var(--primaryYellow);
  font-weight: 600;
}

.aboutText p {
  font-size: var(--pReg);
  color: var(--white);
  line-height: 24px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.whyKlug {
  background-color: var(--white);
  padding: 8rem;
  gap: 16rem;
  display: grid;
  grid-template-columns: auto auto;
}

.whyKlugTextLeft .primaryBtn {
  text-decoration: none;
  background-color: var(--primaryYellow);
  border-radius: 8px;
  border: none;
  color: var(--white);
  padding: 1.2rem 3.2rem;
  font-weight: bold;
  text-align: center;
}

.whyKlug-right {
  display: grid;
  grid-template-areas:
    "title title"
    "value1 value2"
    "value3 value4";
  gap: 2.4rem;
  column-gap: 4rem;
}
.whyKlug-title {
  grid-area: title;
}

.offer1 {
  grid-area: value1;
}

.offer2 {
  grid-area: value2;
}

.offer3 {
  grid-area: value3;
}

.offer4 {
  grid-area: value4;
}

.whyKlug-title h3 {
  font-size: var(--h3);
  line-height: 36px;
  font-weight: 400;
  margin-bottom: 3.2rem;
  margin-top: 0.8rem;
}

.whyKlug .whyKlugTextLeft h1 {
  font-size: var(--h2);
  color: var(--dark);
  font-weight: 400;
  line-height: 5.6rem;
  margin-bottom: 4rem;
}

.whyKlugTextLeft p {
  font-size: var(--pReg);
  color: var(--dark2);
  margin-bottom: 4rem;
}

.whyKlugTextLeft p span {
  margin: 1.6rem 0;
  font-weight: 400;
  display: block;
  color: var(--dark);
  font-size: var(--h5);
}

.whyKlug-right h5 {
  font-size: var(--h4);
  color: var(--dark);
  font-weight: 300;
  margin-bottom: 0.8rem;
}

.whyKlug-right h5 span {
  color: var(--dark);
  font-weight: 600;
}

.whyKlug-right p {
  font-size: var(--pReg);
  font-weight: 400;
  color: var(--dark2);
}

.homeServices {
  background-color: var(--dark);
  padding: 8rem;
}

.homeServices-text {
  padding-bottom: 6.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.homeServices-text h1 span {
  color: var(--primaryYellow);
  font-weight: 600;
}

.homeServices-text h1 {
  font-size: var(--h2);
  color: var(--white);
  font-weight: 400;
  line-height: 56px;
}

.homeServices-text .primaryBtn {
  text-decoration: none;
  background-color: var(--primaryYellow);
  border-radius: 8px;
  border: none;
  color: var(--white);
  padding: 1.2rem 3.2rem;
  font-weight: bold;
  text-align: center;
  display: inline-block;
}

.homeServices .servicePointsHome {
  display: grid;
  grid-template-areas:
    "service1 service2 service3"
    "service4 service5 service6";
  gap: 4rem;
  row-gap: 8rem;
  text-align: center;
}

.service1H {
  grid-area: service1;
}
.service2H {
  grid-area: service2;
}
.service3H {
  grid-area: service3;
}
.service4H {
  grid-area: service4;
}
.service5H {
  grid-area: service5;
}
.service6H {
  grid-area: service6;
}

.servicePointsHome h4 {
  color: var(--primaryYellow);
  font-size: var(--h5);
  font-weight: 500;
  margin: 2.4rem 0 1.6rem 0;
}

.servicePointsHome p {
  color: var(--grey1);
  font-size: var(--pReg);
  max-width: 80%;
  margin: 0 auto;
}

.servicePointsHome img {
  height: 64px;
  width: 64px;
}

.homeServices .servicesBtnResponsive .primaryBtn {
  display: none;
}

.offerSlides {
  position: absolute;
  width: 100%;
}

@media screen and (max-width: 1125px) {
  .container {
    width: 100%;
    overflow: hidden;
    height: 100%;
  }

  .banner {
    width: 100%;
    overflow: hidden;
    height: 100%;
    background-size: cover;
  }

  .bannerContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin: 0;
    padding: 0;
  }

  .banner .bannerLeft {
    display: inline-block;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .banner .bannerImg {
    position: absolute;
    max-width: 100%;
    max-height: 12.5rem;
    margin-top: 1.6rem;
  }

  .banner .bannerImg img {
    width: 100vw;
    height: 250px;
  }

  .banner .bannerText {
    /* margin-top: 30rem; */
    width: 100%;
    padding: 12rem 2.4rem 4rem 2.4rem;
    overflow: hidden;
    word-wrap: break-word;
    text-align: center;
  }

  .banner .bannerText h1 {
    font-size: var(--h3);
    line-height: 48px;
    margin-bottom: 8rem;
  }

  .banner .bannerText p {
    font-size: var(--pXsmall);
    color: var(--dark2);
    line-height: 16px;
    margin-bottom: 0;
  }

  .siteOffers h4 {
    font-size: var(--pReg);
    font-weight: 400;
    color: var(--dark);
    margin-bottom: 0.8rem;
    text-align: center;
  }

  .siteOffers p {
    font-size: var(--pSmall);
    font-weight: 400;
    color: var(--dark2);
    text-align: center;
  }

  .primaryBtn {
    width: 100%;
    margin: 2.4rem 0;
  }

  .banner .primaryBtn {
    margin: 0 0 4.8rem 0;
    padding: 1.2rem;
  }

  .offerSlides {
    position: static;
  }

  .banner .offers {
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 100%;
    overflow: hidden;
  }

  .banner .offers .title {
    margin-bottom: 0.8rem;
  }

  .offers .moneyBack,
  .offers .randomOffer {
    background: var(--grey3);
    border-radius: 0;
    padding: 2.4rem;
  }

  .offers .moneyBack {
    border-bottom: 4px solid var(--white);
  }

  .aboutUs {
    display: grid;
    align-content: center;
    grid-template-columns: 1fr;
    background-repeat: no-repeat;
    margin-top: 0;
    background-color: var(--dark);
    padding: 2.4rem;
  }

  .aboutText {
  }

  .aboutText h1 {
    font-size: var(--h4);
  }

  .aboutText p {
    margin: 24px 0;
    font-size: var(--pSmall);
  }

  .whyKlug {
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding: 2.4rem;
  }

  .whyKlug .whyKlugTextLeft h1 {
    font-size: var(--h4);
    margin-bottom: 2.4rem;
  }

  .whyKlug .whyKlugTextLeft .primaryBtn {
    margin: 0;
  }

  .whyKlug-title h3 {
    font-size: var(--h4);
    margin: 0;
  }

  .whyKlug-right {
    display: grid;
    grid-template-areas:
      "title"
      "value1"
      "value2"
      "value3"
      "value4";
  }

  .whyKlug-right h5 {
    font-size: var(--h5);
    color: var(--dark);
    font-weight: 400;
    margin-bottom: 0.8rem;
  }

  .homeServices {
    padding: 2.4rem;
  }

  .homeServices-text {
    padding-bottom: 4rem;
    display: block;
    text-align: center;
  }

  .homeServices-text h1 {
    font-size: var(--h4);
  }

  .homeServices-text .primaryBtn {
    display: none;
  }

  .homeServices .servicesBtnResponsive .primaryBtn {
    text-decoration: none;
    background-color: var(--primaryYellow);
    border-radius: 8px;
    border: none;
    color: var(--white);
    padding: 1.2rem 3.2rem;
    margin-top: 2.4rem;
    font-weight: bold;
    text-align: center;
    display: inline-block;
  }

  .homeServices .servicePointsHome {
    /* display: grid;
    grid-template-areas:
      "service1 service2"
      "service3 service4"
      "service5 service6";
    gap: 4rem; */
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .servicePointsHome h4 {
    color: var(--white);
    font-size: var(--h5);
    font-weight: 600;
    margin: 2.4rem 0;
  }

  footer {
    display: none;
  }
}
