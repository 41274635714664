.whychooseus-container {
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
}

.whychooseus-banner {
  display: grid;
  column-gap: 2.4rem;
  grid-template-columns: 1fr 1fr;
  margin: 24rem 8rem 8rem 8rem;
}

.whychooseus-banner .whychooseus-bannerLeft {
  align-content: center;
  width: 90%;
}

.whychooseus-bannerLeft h1 {
  font-size: var(--h1);
  color: var(--dark);
  font-weight: 400;
  margin-bottom: 40px;
  line-height: 56px;
}

.gridLeft h2 span {
  color: var(--primaryYellow);
  font-weight: 600;
}

.whychooseus-bannerLeft h1 span {
  color: var(--primaryYellow);
  font-weight: 600;
}

.whychooseus-bannerLeft p {
  font-size: var(--pReg);
  color: var(--dark2);
  line-height: 24px;
}

.whychooseus-bannerLeft p a {
  text-decoration: none;
  color: var(--primaryYellow);
  font-weight: 600;
}

.whychooseus-bannerRight p {
  font-size: var(--pReg);
  color: var(--dark1);
  line-height: 24px;
  margin-bottom: 4rem;
}

.whychooseus-talktous {
  display: grid;
  column-gap: 2.4rem;
  grid-template-columns: 1fr 1fr;
  padding: 8rem;
  background-color: var(--dark);
}

.whychooseus-talktous .gridLeft {
  align-content: center;
  width: 90%;
}

.whychooseus-talktous .gridLeft h2 {
  font-size: var(--h2);
  color: var(--white);
  font-weight: 400;
  margin-bottom: 40px;
  line-height: 56px;
}

.whychooseus-talktous .gridLeft .primaryBtn {
  text-decoration: none;
  background-color: var(--primaryYellow);
  border-radius: 8px;
  border: none;
  color: var(--white);
  padding: 1.2rem 3.2rem;
  font-weight: bold;
  text-align: center;
}

.whychooseus-talktous .gridRight h5 {
  font-size: var(--h5);
  color: var(--white);
  line-height: 24px;
  margin-bottom: 4rem;
}

.whychooseus-talktous .gridRight p {
  font-size: var(--pReg);
  color: var(--white);
  line-height: 24px;
}

.whychooseus-process {
  padding: 8rem;
}

.whychooseus-process .sectionTitle {
  text-align: center;
}

.whychooseus-process .sectionTitle h2 {
  font-size: var(--h2);
  color: var(--dark);
  font-weight: 400;
  margin-bottom: 40px;
  line-height: 56px;
}

.whychooseus-process .sectionTitle span {
  color: var(--primaryYellow);
  display: block;
}

.whychooseus-process .sectionImg {
  text-align: center;
}

.whychooseus-process .sectionImg img {
  max-width: 100%;
}

.whychooseus-philosophy {
  padding: 8rem;
  background-color: var(--dark);
}

.whychooseus-philosophy .sectionTitle {
  display: grid;
  column-gap: 2.4rem;
  grid-template-columns: 1fr 1fr;
}

.whychooseus-philosophy .sectionTitle h2 {
  font-size: var(--h2);
  color: var(--white);
  font-weight: 400;
  margin-bottom: 8rem;
  line-height: 56px;
}

.whychooseus-philosophy .sectionTitle h2 span {
  color: var(--primaryYellow);
}

.whychooseus-philosophy .sectionTitle p {
  font-size: var(--pReg);
  color: var(--grey1);
  line-height: 24px;
}

.whychooseus-philosophy .sectionContent {
  display: grid;
  grid-template-areas: "philosophy1 philosophy2 philosophy3 philosophy4";
  gap: 2rem;
}

.whychooseus-philosophy .sectionContent .bgcolordark {
  background-color: var(--dark1);
}

.philosophy1 {
  grid-area: philosophy1;
  padding: 2.4rem;
}

.philosophy2 {
  grid-area: philosophy2;
  padding: 2.4rem;
}

.philosophy3 {
  grid-area: philosophy3;
  padding: 2.4rem;
}

.philosophy4 {
  grid-area: philosophy4;
  padding: 2.4rem;
}

.whychooseus-philosophy .sectionContent h5 {
  font-size: var(--h5);
  color: var(--white);
  line-height: 24px;
  font-weight: 600;
  margin: 2rem 0;
}

.whychooseus-philosophy .sectionContent p {
  font-size: var(--pReg);
  color: var(--grey1);
  font-weight: 400;
  line-height: 24px;
}

.whyusServices {
  background-color: var(--grey3);
  padding: 8rem;
}
.whyusServices-text {
  padding-bottom: 8rem;
  justify-content: center;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.whyusServices-text h1 span {
  color: var(--primaryYellow);
  font-weight: 600;
}

.whyusServices-text h1 {
  font-size: var(--h2);
  color: var(--dark);
  font-weight: 400;
  line-height: 56px;
}

.whyusServices-text .primaryBtn {
  text-decoration: none;
  background-color: var(--primaryYellow);
  border-radius: 8px;
  border: none;
  color: var(--white);
  padding: 1.2rem 3.2rem;
  font-weight: bold;
  text-align: center;
  display: inline-block;
}

.whyusServices .servicePoints {
  display: grid;
  grid-template-areas:
    "service1 service2 service3"
    "service4 service5 service6";
  gap: 8rem 4rem;

  text-align: center;
}

.whyusServicePoints {
  text-align: left;
}

.service1 {
  grid-area: service1;
}
.service2 {
  grid-area: service2;
}
.service3 {
  grid-area: service3;
}
.service4 {
  grid-area: service4;
}
.service5 {
  grid-area: service5;
}
.service6 {
  grid-area: service6;
}

.servicePoints h5 {
  color: var(--dark);
  font-size: var(--h5);
  font-weight: 600;
  margin: 1.6rem 0 1.6rem 0;
}

.servicePoints p {
  color: var(--dark2);
  font-size: var(--pReg);
  max-width: 80%;
  margin: 0;
}

.servicePoints img {
  height: 2.4rem;
  width: 2.4rem;
}

.whychooseus-btnresponsive .primaryBtn {
  display: none;
}

@media screen and (max-width: 1125px) {
  .whychooseus-container {
    width: 100%;
    overflow: hidden;
    height: 100%;
    margin-top: 8rem;
  }

  .whychooseus-banner {
    display: flex;
    flex-direction: column;
    margin: 4rem 2.4rem;
  }

  .whychooseus-bannerLeft h1 {
    font-size: var(--h3);
    margin-bottom: 2.4rem;
    line-height: 56px;
  }

  .whychooseus-bannerLeft h1 span {
    color: var(--primaryYellow);
    font-weight: 600;
  }

  .whychooseus-bannerLeft p {
    font-size: var(--pSmall);
    color: var(--dark2);
    line-height: 24px;
  }

  .whychooseus-bannerLeft p a {
    text-decoration: none;
    color: var(--primaryYellow);
    font-weight: 600;
  }

  .whychooseus-bannerRight p {
    font-size: var(--pSmall);
    color: var(--dark1);
    line-height: 24px;
    margin-bottom: 2.4rem;
  }

  .whychooseus-talktous {
    display: flex;
    padding: 2.4rem;
    flex-direction: column;
  }

  .whychooseus-talktous .gridLeft h2 {
    margin-bottom: 2.4rem;
    line-height: 40px;
    font-size: var(--h4);
  }

  .whychooseus-talktous .gridLeft .primaryBtn {
    display: none;
  }

  .whychooseus-btnresponsive .primaryBtn {
    text-decoration: none;
    background-color: var(--primaryYellow);
    border-radius: 8px;
    border: none;
    color: var(--white);
    padding: 1.2rem 3.2rem;
    font-weight: bold;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin-top: 2.4rem;
  }

  .whychooseus-talktous .gridRight h5 {
    font-weight: 400;
    font-size: var(--h5);
    margin-bottom: 2.4rem;
  }

  .whychooseus-talktous .gridRight p {
    font-size: var(--pSmall);
  }

  .whyusServices {
    background-color: var(--grey3);
    padding: 2.4rem 2.4rem 4rem 2.4rem;
  }
  .whyusServices-text {
    padding-bottom: 4rem;
    justify-content: center;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .whyusServices-text h1 {
    font-size: var(--h4);
    color: var(--dark);
    font-weight: 400;
    line-height: 40px;
  }

  .whyusServices .servicePoints {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .servicePoints p {
    color: var(--dark2);
    font-size: var(--pSmall);
    max-width: 100%;
    margin: 0;
  }

  .whyusServicePoints {
    text-align: center;
  }
}
