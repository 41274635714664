.sidebar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--dark);
  color: var(--white);
  transition: left 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 2.4rem 4.8rem 2.4rem;
  left: -100%;
  z-index: 9999;
  overflow-y: auto;
}

.sidebar.open {
  left: 0;
}

.menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 5rem;
}

.logo {
  font-size: 1.5em;
  font-weight: bold;
  color: var(--primaryYellow);
  width: 50%;
}

.close-btn {
  background: none;
  border: none;
  font-size: 4rem;
  color: var(--primaryYellow);
  cursor: pointer;
  width: 50%;
  text-align: right;
}

.menu-items {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.menu-items a {
  color: var(--white);
  text-decoration: none;
  margin: 2rem 0;
  font-size: var(--pReg);
}

.menu-items hr {
  height: 1px;
  color: var(--dark1);
  background: var(--dark1);
  font-size: 0;
  border: 0;
}

.contact-btn {
  background-color: var(--primaryYellow);
  border: none;
  border-radius: 8px;
  color: var(--white);
  padding: 0.625rem 0;
  text-align: center;
  font-size: var(--pReg);
  cursor: pointer;
}

.contact-btn:hover {
  background-color: var(--primaryYellow);
}

.copyright {
  text-align: center;
  font-size: var(--pSmall);
  color: var(--dark2);
  margin: 1.5rem 0;
}

.menu-items a.active {
  color: var(--primaryYellow);
  font-weight: 600;
}
