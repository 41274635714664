@keyframes blink {
  0% {
    border-color: transparent;
  }
  100% {
    border-color: black;
  }
}

.cursor {
  border-right: 2px solid black;
  animation: blink 0.75s step-end infinite;
}

.hidden {
  animation: none;
}

@media screen and (max-width: 1125px) {
  .typingEffectText {
    word-wrap: break-word;
    overflow: hidden;
    width: 100%;
    font-size: var(--h3);
    font-weight: 400;
  }
}
